<template>
  <b-modal
    id="IndemnityPriceClinicTemplateDeleteModal"
    visible
    scrollable
    centered
    size="md"
    title="Удаление шаблона"
    @hidden="onClose"
    @ok="onClose"
  >
    <div>
      Вы уверены, что хотите удалить шаблон?
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        size="sm"
        @click="onDelete"
      >
        Удалить
      </b-button>
      <b-button
        variant="outline-primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        size="sm"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'IndemnityPriceClinicTemplateDeleteModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    template: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    isOpen: true,
    isLoading: false,
    data: {
      id: null,
      name: null,
      prices: [],
    },
    priceSearch: {
      name: '',
      isLoading: false,
      prices: [],
      fields: [
        {
          key: 'code',
          label: 'Код услуги',
        },
        {
          key: 'name',
          label: 'Название услуги',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'action-td',
          thClass: 'action-td',
        },
      ],
    },
  }),

  methods: {
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(this.$types.PRICE_CLINIC_TEMPLATE_DELETE, {
          templateId: this.template.id,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
        this.$emit('doAfterDelete');
        this.onClose();
      }
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#IndemnityPriceClinicTemplateDeleteModal___BV_modal_outer_ {
  z-index: 1050!important;
}
</style>
